<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ test.entity.name }}</span>
    </v-card-title>
    <!-- <pre>{{test}}</pre> -->
    <v-card-text>
      <div class="text-justify black--text" v-html=" $t('suportExplainHi') +' '+ currentUser.name +',<br />'+ text">
       </div>

       <div v-html="$t('info_test').replace('DURATION', this.test.duraction)"></div>

        <br/>
      <span class="black--text">{{ $t('good_job') }}</span>
      <br/>
      <br/>
      <span class="black--text">{{ $t('recruitment_team').replace("entity",test.entity.name) }} </span>
    </v-card-text>
    <v-card-actions>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-6 py-8">
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="$emit('close')" dense text>{{
          $t("cancel")
        }}</v-btn>

        <v-btn type="submit" color="primary" sense @click="$emit('start')"
          >{{$t('IntervieStart')}}</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InfoTest",
  props: {
    test: Object,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),

    text() {
      var result = this.$t("test_text").replace("TITLE", this.test.vacancy.title);
      result = result.replace("DURATION", this.test.duraction);
      return result;
    },
  },
};
</script>