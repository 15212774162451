<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card
      :disabled="test.status || days(test).status === $t('expired')"
      :elevation="hover ? 16 : 2"
     
    >
    <!-- <pre>{{ test }}</pre> -->
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-btn tile x-small dense  :color="days(test).color">
          {{ days(test).status }}
        </v-btn>
      </v-system-bar>

      <v-card-title class="subtitle">
        <strong>{{ test.entity.name }}</strong>
      </v-card-title>

      <v-list-item >
        <v-list-item-content>
          <v-list-item-subtitle class="caption"
            >{{$t('Job_title')}}</v-list-item-subtitle
          >
          <v-list-item-title class="subtitle black--text" v-html="test.vacancy ? test.vacancy.title : '<p></p><p></p><p></p>'">
           
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item >
        <v-list-item-content>
          <v-list-item-subtitle class="caption"
            >{{$t('testtitle')}}</v-list-item-subtitle
          >
          <v-list-item-title class="subtitle black--text">
            {{  test.title ? test.title : "" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item >
        <v-list-item-content>
          <v-list-item-subtitle class="caption"
            >{{$t('available_until')}}</v-list-item-subtitle
          >
          <v-chip-group active-class="amber darken-1 white--text " column>
            <v-chip small>{{ localDate(test.date) }} {{ test.time }}</v-chip>
          </v-chip-group>
        </v-list-item-content>
      </v-list-item>

      <!-- <pre>{{test}}</pre> -->
      <v-divider class="mx-4"></v-divider>

  

        <v-list-item >
        <v-list-item-content>
          <v-list-item-title class="subtitle-1  black--text">
           <strong>{{$t('Duration')}}:</strong> {{test.duraction}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        <v-list-item style="margin-top:-5%">
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 black--text">
           <strong>{{$t('Questions')}}:</strong>  {{test.questions.length}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-card-actions>
       <v-row class="d-flex flex-row justify-end align-center mt-3 px-6 py-4">
          <v-spacer></v-spacer>
          <v-btn small color="primary" type="submit"  @click="$emit('solve', test)">
           {{$t('advance')}}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import moment from "moment";
export default {
  name: "TestCard",
  props: {
    test: Object,
  },
  methods: {
    days(test) {
      if (test.status) {
        return { status: this.$t('concluded'), color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale(this.$i18n.locale);
      const now = moment().format("YYYY-MM-DD H:mm");

      const days = finalDate
        .endOf("day")
        .fromNow()
        .split(" ");

      if (now < finalDate._i) {
        return { status: days[1] + " " + days[2], color: "warning" };
      } else {
        return { status: this.$t('expired'), color: "error" };
      }
    },
    localDate(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale);
    },
  },
};
</script>
