<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="status === 'finish' ? '400' : '800'"
    :fullscreen="isMobile"
  >
    <!-- {{ isMobile }} -->
    <info-test
      :test="test"
      @close="closeDialog()"
      @start="setStart()"
      v-if="status === 'stop'"
    />
    <quiz
      :start="start"
      :test="test"
      @finish="finish"
      @upload="upload"
      v-else-if="status === 'start'"
    />
    <v-card v-else-if="status === 'finish'">
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-btn small icon color="primary" @click="closeDialog()">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card-text
        class="black--text d-flex flex-column text-center justify-center pt-1 pb-8"
      >
        <div class="mb-2">
          <v-icon large color="success">mdi-check-circle-outline</v-icon>
        </div>
        <div>
          <span v-html="$t('solveTest')"> </span>
        </div>
        <div class="pt-2">
          <v-btn small color="primary" @click="closeDialog()">
            <span class="white--text">{{ $t("close") }}</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- <v-card-title class="subtitle-1 text-center justify-center py-5">
        <span v-html="$t('solveTest')"> </span>
      </v-card-title>
      <v-row justify="center">
        <v-col cols="12" md="6" class="pa-5 pt-0">
          <v-btn block color="primary" large @click="closeDialog()">{{
            $t("close")
          }}</v-btn>
        </v-col>
      </v-row> -->
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { SOLVE_TEST_MUTATION } from "./../graphql/Mutation.service";
import { mapActions,mapGetters } from "vuex";
import { formatError } from "@/utils";
import InfoTest from "./InfoTest.vue";
import Quiz from "./Quiz.vue";
import ErrorDialog from "./../../../components/ErrorDialog";
import { ADD_DIALOG } from "@/mixins/dialog";
import axios from "axios";
export default {
  components: { ErrorDialog, InfoTest, Quiz },
  name: "SolveTestDialog",
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    test: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    status: "stop",
    start: false,
    isMobile: false,
    questions: [],
  }),
  created() {
    this.status = "stop";
    this.start = false;
    if (/Mobi|Android/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      console.log("É um dispositivo móvel");
    } else {
      this.isMobile = false;
      console.log("Não é um dispositivo móvel");
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),},
  methods: {
    ...mapActions({
      updateTestState: "test/updateTest",
    }),
    closeErrorDialog(value) {
      this.showError = value;
      this.status = "stop";
      this.start = false;
      this.$emit("close");
    },
    async finish(params) {
      try {
        this.status = "finish";
        this.start = false;
        await this.setQuestionFile(params.questions);
        console.log("enviar", params.questions);
        const { data } = await this.$apollo.mutate({
          mutation: SOLVE_TEST_MUTATION,
          variables: { ...params },
        });
        this.updateTestState(data.solveTest);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },

    async setQuestionFile(questions) {
      await Promise.all(
        this.test.questions.map(async (question, key) => {
          if (question.doc) {
            console.log("doc",question.doc);
            question.fileResponse = await this.uploadQuestionFile(
              question.doc
            );
            questions[key].fileResponse = question.fileResponse 
             delete question.doc
          }
          return question;
        })
      );
    },
   upload(file, pos) {
    file[file.length - 1].name
      console.log(file[file.length - 1].name, pos);
      const lastfile = file[file.length - 1];
      this.test.questions[pos].doc = lastfile;
    },
    async uploadQuestionFile(file) {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", this.currentUser.id);
        try {
          const response = await axios.post(
            `${API_URL}/test/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // console.log(response.data.filename);
          return response.data.filename;
        } catch (error) {
          console.error(error); // Lida com erros durante o upload
        }
      }
    },
    closeDialog() {
      this.status = "stop";
      this.start = false;
      this.dialog = false;
      this.$emit("close");
    },
    setStart() {
      this.status = "start";
      this.start = true;
    },
  },
};
</script>